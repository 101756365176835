



















import { defineComponent } from '@vue/composition-api';
import GatewayDetailBreakdownLine from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailBreakdownLine.vue';
import { GatewayDetails_gateways_first } from '@/features/app-manager/views/tree-node/gateway-new-details/__generated__/GatewayDetails';

interface GatewayDetailBreakdownProps {
  gatewayDetails: GatewayDetails_gateways_first;
}
export default defineComponent<GatewayDetailBreakdownProps, Record<string, unknown>, any, any>({
  components: { GatewayDetailBreakdownLine },
  props: {
    gatewayDetails: {
      type: Object as () => GatewayDetails_gateways_first,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    firmware() {
      if (this.gatewayDetails.lifecycleMessage?.firmware) {
        return (
          this.gatewayDetails.lifecycleMessage?.firmware?.model +
          ' ' +
          this.gatewayDetails.lifecycleMessage?.firmware?.version
        );
      }
      return 'Keine Firmware-Daten';
    },
    manufactuter() {
      if (this.gatewayDetails.lifecycleMessage?.firmware?.model) {
        const model = this.gatewayDetails.lifecycleMessage?.firmware?.model.trim().toLowerCase();
        if (model.includes('mgw')) {
          return 'Develco';
        } else if (model.includes('gcx')) {
          return 'GCX';
        }
      }
      return 'Keine Herstellerangaben';
    },
    mobileImsiNumber() {
      return this.gatewayDetails.configuration?.mobileImsiNumber;
    },
  },
  methods: {},
});
